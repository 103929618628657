import logo from "../src/icon.gif";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        {/* <a>
          Awantika Bhattarai
        </a> */}
      </header>
    </div>
  );
}

export default App;
